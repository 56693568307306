var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"onlyIcons":true}}),_c('section',[_c('article',{staticStyle:{"height":"100px","width":"390px"}},[_c('field',{staticStyle:{"top":"9px","left":"25px"},attrs:{"name":"codigo","widget":"input","searchable":"","label":"Código","placeholder":"Cód.","width":"67px","readonly":_vm.mode == 'edit',"inputStyle":"font-weight:bold;text-align:center;"}}),_c('field',{staticStyle:{"top":"9px","left":"105px"},attrs:{"name":"descripcion","widget":"input","searchable":"","label":"Coste Reparación","help":"Descripcion","placeholder":"Descripcion","width":"240px"}}),_c('field',{staticStyle:{"top":"49px","left":"25px"},attrs:{"name":"precio_coste","widget":"input","type":"number","searchable":"","label":"Precio coste","placeholder":"0.00","width":"100px"},on:{"change":function($event){_vm.$set(
            _vm.formData,
            'precio_venta',
            (
              (1 + parseFloat(_vm.formData.margen || 0) / 100) *
              parseFloat(_vm.formData.precio_coste)
            ).toFixed(2)
          )}}}),_c('field',{staticStyle:{"top":"49px","left":"135px"},attrs:{"name":"margen","widget":"input","type":"number","searchable":"","label":"Margen","placeholder":"0","width":"100px","sufix":"%"},on:{"change":function($event){_vm.$set(
            _vm.formData,
            'precio_venta',
            (
              (1 + parseFloat(_vm.formData.margen || 0) / 100) *
              parseFloat(_vm.formData.precio_coste)
            ).toFixed(2)
          )}}}),_c('field',{staticStyle:{"top":"49px","left":"245px"},attrs:{"name":"precio_venta","widget":"input","type":"number","searchable":"","label":"Precio venta","placeholder":"0.00","width":"100px"},on:{"change":function($event){_vm.$set(
            _vm.formData,
            'margen',
            (
              (parseFloat(_vm.formData.precio_venta) /
                parseFloat(_vm.formData.precio_coste) -
                1) *
              100
            ).toFixed(2)
          )}}})],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }