<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 100px; width: 390px">
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cód."
          width="67px"
          style="top: 9px; left: 25px"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="descripcion"
          widget="input"
          searchable
          label="Coste Reparación"
          help="Descripcion"
          placeholder="Descripcion"
          width="240px"
          style="top: 9px; left: 105px"
        />
        <field
          name="precio_coste"
          widget="input"
          type="number"
          searchable
          label="Precio coste"
          placeholder="0.00"
          width="100px"
          style="top: 49px; left: 25px"
          @change="
            $set(
              formData,
              'precio_venta',
              (
                (1 + parseFloat(formData.margen || 0) / 100) *
                parseFloat(formData.precio_coste)
              ).toFixed(2)
            )
          "
        />
        <field
          name="margen"
          widget="input"
          type="number"
          searchable
          label="Margen"
          placeholder="0"
          width="100px"
          style="top: 49px; left: 135px"
          sufix="%"
          @change="
            $set(
              formData,
              'precio_venta',
              (
                (1 + parseFloat(formData.margen || 0) / 100) *
                parseFloat(formData.precio_coste)
              ).toFixed(2)
            )
          "
        />
        <field
          name="precio_venta"
          widget="input"
          type="number"
          searchable
          label="Precio venta"
          placeholder="0.00"
          width="100px"
          style="top: 49px; left: 245px"
          @change="
            $set(
              formData,
              'margen',
              (
                (parseFloat(formData.precio_venta) /
                  parseFloat(formData.precio_coste) -
                  1) *
                100
              ).toFixed(2)
            )
          "
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Coste Reparación",
      dbAdapter: "coste_reparacion",
      primary: "codigo",
      sequence: { name: "coste_reparacion" },
    };
  },
};
</script>
